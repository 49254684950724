import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

//import images
import Img1 from '../assets/images/features/img-1.png';

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricing: [
        {
          id: 1,
          title: "Starter",
          description: 'Semper urna veal tempus pharetra elit habisse platea dictumst.',
          icon: 'mdi-account',
          titlePrimary: false,
          regularprice: '$9.99',
          saleprice: '$8.99',
          pricingTag: false,
          child: [
            { btitle: 'Unlimited', title: "Target Audience", icon: "mdi-checkbox-marked-circle text-success" },
            { btitle: '1', title: "User Account", icon: "mdi-checkbox-marked-circle text-success" },
            { btitle: '100+', title: "Video Tuts", icon: "mdi-close-circle text-danger" },
            { btitle: 'Public', title: "Displays", icon: "mdi-close-circle text-danger" },
          ],
        },
        {
          id: 2,
          title: "Personal",
          titlePrimary: true,
          description: 'Semper urna veal tempus pharetra elit habisse platea dictumst.',
          icon: 'mdi-account-multiple text-primary',
          regularprice: '$19.99',
          saleprice: '$18.99',
          pricingTag: true,
          child: [
            { btitle: 'Unlimited', title: "Target Audience", icon: "mdi-checkbox-marked-circle text-success" },
            { btitle: '1', title: "User Account", icon: "mdi-checkbox-marked-circle text-success" },
            { btitle: '100+', title: "Video Tuts", icon: "mdi-close-circle text-success" },
            { btitle: 'Public', title: "Displays", icon: "mdi-close-circle text-danger" },
          ],
        },
        {
          id: 3,
          title: "Ultimate",
          description: 'Semper urna veal tempus pharetra elit habisse platea dictumst.',
          titlePrimary: false,
          icon: 'mdi-account-multiple-plus',
          regularprice: '$29.99',
          saleprice: '$28.99',
          pricingTag: false,
          child: [
            { btitle: 'Unlimited', title: "Target Audience", icon: "mdi-checkbox-marked-circle text-success" },
            { btitle: '1', title: "User Account", icon: "mdi-checkbox-marked-circle text-success" },
            { btitle: '100+', title: "Video Tuts", icon: "mdi-close-circle text-success" },
            { btitle: 'Public', title: "Displays", icon: "mdi-close-circle text-success" },
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light bg-featuress">
          <Container>
            <Row className="align-items-center">
              <Col lg={5}>
                <div className="mt-4 home-img">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                  <img src={Img1} className="img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={6} className="offset-lg-1">
                <div className="mt-5">
                  <h2>OUR CAPACITY AND CAPABILITY </h2>
                  <p className="mt-4 text-muted">Following the successful completion of various projects between Nigerian Petroleum Development Company(NPDC) and other JV partners, GYN 
has witnessed a success story in the area of JV operations. 
                            <p></p>
                            Our success stories have stood us out as testimonies to our high professionalism, team work and 
deployment.
                            <p></p>   
                            Our capabilities range from the under listed services:
                           <p></p>
                              ▪ Transport and Logistics.
                              <p></p>
                              ▪ Event Management.
                              <p></p>
                              ▪ Consultancy Management.
                              <p></p>
                              ▪ Supply Chain Management Services.
                              <p></p>
                              ▪ Journey Management Planning (JMP).
                              <p></p>
                              ▪ Hospitality Management.
                             
</p>

                  
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        
      </React.Fragment>
    );
  }
}
