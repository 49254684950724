import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

//import images
import Img1 from '../assets/images/features/img-1.png';

export default class Pricing extends Component {
 

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light bg-features">
          <Container>
            <Row className="align-items-center">
              <Col lg={5}>
                <div className="mt-4 home-img">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                  <img src={Img1} className="img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={6} className="offset-lg-1">
                <div className="mt-4">
                <p className="text-uppercase">Who We Are</p>
                  <h2>OUR KEY PERSONNEL</h2>
                  <p className="mt-4 text-muted"><b>MR UBOGU EMAMUZO GABRIEL – Director</b> <p></p>
Mr. UBOGU Emamuzo Gabriel is an Accountant/Finance expert by profession. Who has been 
working in the Oil and Gas sector for about 11 years. His dedication to work and business has 
earned him feat in managerial position, which gave him the opportunity to develop his 
remarkable skills in the field of human resources and consultancy.</p>

                  <div className="mt-4">
                    <Link to="personnel" className="btn btn-primary">Learn More</Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        
      </React.Fragment>
    );
  }
}
