import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Modal Video


//import images
import HomeUrl from '../../assets/images/home-border.png';
import Typewriter from 'typewriter-effect';

class Section extends Component {
  

  render() {
    return (
      <React.Fragment>
        <section className="bg-home home-bg-2" id="home">
          <div className="bg-overlay"></div>
          <div className="home-center">
            <div className="home-desc-center">
              <Container>
                <Row className="justify-content-center">
                  <Col lg={9}>
                    <div className="home-content text-center">
                      <p className="mb-0 text-white"></p>
                      <img src={HomeUrl} height="15" alt="" />
                      <h1 className=" home-title text-white"><span className="element text-primary" data-elements="G.Y.N"><Typewriter
                              options={{
                                strings: ['G.Y.N'],
                                autoStart: true,
                                loop: true,
                              }}
                            />
                            <h1 className=" home-title text-white">GLOBAL RESOURCES</h1></span></h1>

                      <p className="text-white-50 mt-4 f-20"></p>
                      <div className="mt-4 pt-2">
                        <Link to="contact" className="btn btn-primary mr-3">Talk To Us</Link>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
              
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
