import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import Img1 from '../assets/images/Logistics.jpg';
import HomeUrl from '../assets/images/home-border.png';

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
       
       
        
      ],
      counters: [
       
        
        
        
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="services">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">WORKFORCE PROFILE </h3>
                  <p className="text-muted f-17 mt-3">The GYN Global Resources team is made up of highly skilled personnel and business 
practitioners whose competency and experience have ensured the delivery of quality services.From time to time, the company also brings on board ‘hands on’ consultants with extensive 
experience that caters to both Government and Commercial clients. <p></p> We employ indigenous skilled personnel who are constantly being trained and updated on 
the latest trend in the world of business. We have a team of experienced back-office staff, 
including professionals in the fields of logistics, human resources and business 
administration. </p>

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4">
                    <div className=" bg-soft-primary">
                    </div>

                    <h5 className="mt-4">{item.title}</h5>
                    <p className="text-muted mt-3">{item.description}</p>

                    <div className="mt-3">
                    </div>

                  </div>
                </Col>
              ))}
            </Row>
            <Row className="align-items-center mt-5 pt-4" id="counter">
              <Col lg={6}>
                <div className="pr-4 mt-4">
                  <p className="text-uppercase"></p>
                  <img src={Img1} className="img-fluid" alt="" />
                  <h3>OUR CLIENTELE</h3>
                  <p className="text-muted mt-3">In our commitment to being in the forefront of Nigeria’s Logistics firm geared towards the 
provision of satisfactory services to our clients, GYN Global Resources is on the verge of 
entering in partnership with reputable local and international firms. GYN have rendered 
services to the under listed firms: 
                            <p>1 - Nigerian Petroleum Development Company(NPDC)-Shoreline Natural Resources Limited: OML 30 Asset</p>
                            <p>2 - Nigerian Petroleum Development Company(NPDC) – Elcrest: OML 40 Asset</p>
                            <p>3 - Nigerian Petroleum Development Company(NPDC) – Shell OML 20 Asset</p>
                            <p>4 - Nigerian Petroleum Development Company(NPDC) – Chevron OML 49 Asset</p>
                            <p>5 - Nigerian Petroleum Development Company(NPDC) – SEEPCO: OML 13</p>
                            <p>6 - Nigerian Petroleum Development Company(NPDC) – OML 11</p>
                            <p>7 - Nigerian Petroleum Development Company(NPDC).NNPC Core Asse</p>
 </p>
                  <div className="mt-4 pt-1">
                    
                  </div>
                </div>
              </Col>
              <Col lg={5} className="offset-lg-1">
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div className={ (counteritem.id !== '1') ? 'mt-4 pt-3' : 'mt-4' } key={key}>
                      <div className="media">
                        <div className="count-box bg-soft-primary text-center">
                          <h3 className="counter-count mb-1 text-primary"> 
                          
                            <span className="count-plus text-primary"> </span></h3>
                          <p className="text-uppercase text-muted mb-0 f-14">{counteritem.title} </p>
                        </div>
                        <div className="media-body pl-4">
                          <p className="text-muted mb-0 mt-3">{counteritem.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
