import React, { Component } from "react";
import emailjs from '@emailjs/browser';


import { Col, Container, Form, FormGroup, Input, Label, Row } from "reactstrap";



import HomeUrl from '../assets/images/home-border.png';
import Feature from '../assets/images/features/img-3.png';



export default class Contact extends Component {
  state = {
    name: '',
    lastname: '',
    email: '',
    message: '',
  }
handleSubmit(e) {
    e.preventDefault()
    const { name, lastname, email, message } = this.state
    let templateParams = {
      email: email,
      lastname: lastname,
      to_name: name,
      message: message,
     }
     emailjs.send('service_fzvuswn', 'template_1fyu5nu', templateParams, 'oeHarIMNamfOEKcBP')
     alert("Message Sent."); 
     this.resetForm()
 }
resetForm() {
    this.setState({
      name: '',
      lastname: '',
      email: '',
      message: '',
    })
  }
handleChange = (param, e) => {
    this.setState({ [param]: e.target.value })
  }

  render() {
    return (
      <React.Fragment>
        <section className="section" id="contact">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="title-box text-center">
                <img src={HomeUrl} height="15" className="mt-3" alt="" />
                  <h3 className="title-heading mt-4">GET IN TOUCH!</h3>
                  <p className="text-muted f-17 mt-3"><b>Our Address: </b> No. 6 Pa Semeon Osaghae Drive, Off Sapele Road, Benin City, Edo State.

                             <p></p> For all enquiries, please email us using the form below</p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              <Col lg={6}>
                <div className="mt-4 home-img text-center">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                  <img src={Feature} className="img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={6}>
                <div className="custom-form mt-4">
                  <div id="message"></div>
                  <Form onSubmit={this.handleSubmit.bind(this)}>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">First Name</Label>
                          <Input name="name" id="name" className="form-control" type="text" value={this.state.name} onChange={this.handleChange.bind(this, 'name')} required/>
                        </FormGroup>
                      </Col>
                      <Col lg={6}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">Last Name</Label>
                          <Input name="name" id="lastname" className="form-control" type="text" value={this.state.lastname} onChange={this.handleChange.bind(this, 'lastname')} required/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">Email Address</Label>
                          <Input name="email" id="email" className="form-control" type="email" value={this.state.email} onChange={this.handleChange.bind(this, 'email')} required/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12}>
                        <FormGroup className="mt-3">
                          <Label className="contact-lable">Your Message</Label>
                          <Input type="textarea" name="message" id="message" rows="5" className="form-control" value={this.state.message} onChange={this.handleChange.bind(this, 'message')} required/>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} className="mt-3 text-right">
                        <Input id="submit"  color="primary" className="submitBnt btn btn-primary btn-round" value="Send Message" type="submit" style={{width: 'auto',color: '#fff'}} />{' '}
                        <div id="simple-msg"></div>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }

}
