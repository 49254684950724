import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";
import Slider from "react-slick";

import Background1 from '../../assets/images/img2.jpg';
import Background2 from '../../assets/images/img3.jpg';
import Background3 from '../../assets/images/bg-3.jpg';

import Typewriter from 'typewriter-effect';



class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideitems: [
        {
          id: 100,
          img: Background1,
        },
        {
          id: 101,
          img: Background2,
        },
        {
          id: 103,
          img: Background3,
        },
      ],
    };
  }

  render() {
    var settings = {
      autoplay: true,
      dots: false,
      speed: 300,
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      
      
    };

    const slides = this.state.slideitems.map((item, key) => {
      return (
        <div className="carousel-item" key={key}>
          <div style={{ backgroundImage: `url(${item.img})`, backgroundPosition: 'center center', backgroundSize: 'cover', height: '100vh' }}>
            <div className="bg-overlay"></div>
            <div className="home-center">
              <div className="home-desc-center">
                <Container>
                  <Row className="align-items-center justify-content-center">
                    <Col lg="10">
                      <div className="home-content text-center text-white">
                      <h1 className=" home-title text-white"><span className="element text-primaryy" data-elements="G.Y.N"><Typewriter
                              options={{
                                strings: ['G.Y.N'],
                                autoStart: true,
                                loop: true,
                              }}
                            />
                            <h1 className=" home-title text-white">GLOBAL RESOURCES</h1></span></h1>
                        <div className="mt-5">
                          <div className="mt-4 pt-2">
                            <Link to="contact" className="btn btn-primary mr-3">Contact Us</Link>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
          </div>
        </div>
      );
    });

    return (
      <React.Fragment>
        <section className="home-slider" id="home">
          <Container fluid>
            <Row>
              <div id="carouselExampleControls" className="carousel slide">
                <div className="carousel-inner">
                  <Slider {...settings}>
                    {slides}
                  </Slider>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
