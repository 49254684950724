import React, { Component } from "react";
import { Container } from "reactstrap";
import FooterLink from "../Footer/Footer_link";


class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      links: [
        
       
        
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        {/* Footer Start */}
        <footer
          className="section bg-light bg-footer pb-5"
        >
          <Container>
            
           
            {/* Render Footer Link End */}
            <FooterLink />
          </Container>
        </footer>
        {/* Footer End */}
      </React.Fragment>
    );
  }
}

export default Footer;
