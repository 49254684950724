import React, { Component } from "react";

// Importing Section
import Navbar from "../../component/Navbar/NavBar";

import Services2 from "../../component/Services2";
import Footer from "../../component/Footer/Footer";
import Pricing2 from "../../component/Pricing2";

class Layout2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: "", navheading: "Company" },
        { id: 3, idnm: "personnel", navheading: "Who We Are" },
        { id: 4, idnm: "strength", navheading: "Our Strength" },
        { id: 5, idnm: "contact", navheading: "Contact Us" },
        
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: "nav-sticky",
      fixTop : true
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: "nav-sticky", imglight: false });
    } else {
      this.setState({ navClass: "navbar-sticky", imglight: false });
    }
  };

  render() {
    return (
      <React.Fragment>
          {/* Importing Navbar */}
          <Navbar
            navItems={this.state.navItems}
            navClass={this.state.navClass}
            imglight={this.state.imglight}
            top={this.state.fixTop}
          />

          {/* Importing Section */}
          <Pricing2 />

           {/* Importing Service */}
           <Services2 />

          {/* Importing Pricing */}
          

          {/* Importing Team */}
          

         

          {/* Importing Footer */}
          <Footer />
      </React.Fragment>
    );
  }
}
export default Layout2;
