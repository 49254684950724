import React, { Component } from "react";
import routes from "../src/routes";
import {
  withRouter,
  Route,
  Switch,
  BrowserRouter as Router,
} from "react-router-dom";


import "./assets/css/materialdesignicons.min.css";

import "./assets/scss/themes.scss";
import ScrollToTop from "./component/Navbar/scrolltotop";

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <ScrollToTop />
          <Switch>
            {routes.map((route, idx) => (
              <Route path={route.path} component={route.component} key={idx} />
            ))}
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}

export default withRouter(App);
