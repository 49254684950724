import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import Img1 from '../assets/images/img22.jpg';
import HomeUrl from '../assets/images/home-border.png';

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
       
       
        
      ],
      counters: [
       
        
        
        
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="services">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">COMPANY STRATEGY </h3>
                  <p className="text-muted f-17 mt-3"><b>• Purpose:</b> To be a leader in the general Merchandise and real estate industry by providing 
enhanced services, relationship and profitability.
                                        <p></p><b> • Mission statement:</b> To make GYN Global Resources a foremost Merchandise and logistic 
service provider to our customers; providing a desirable place of work for our staff and a 
rewarding experience for our stakeholders.
                                        <p></p><b> • Values:</b>
<br></br>
o Distinction
<br></br>
o Novelty
<br></br>
o Integrity
<br></br>
o Trust
 </p>

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4">
                    <div className=" bg-soft-primary">
                    </div>

                    <h5 className="mt-4">{item.title}</h5>
                    <p className="text-muted mt-3">{item.description}</p>

                    <div className="mt-3">
                    </div>

                  </div>
                </Col>
              ))}
            </Row>
            <Row className="align-items-center mt-5 pt-4" id="counter">
              <Col lg={6}>
                <div className="pr-4 mt-4">
                  <p className="text-uppercase"></p>
                  <img src={Img1} className="img-fluid" alt="" />
                  <h3>EXECUTED JOBS BY GYN GLOBAL RESOURCES</h3>
                  <p className="text-muted mt-3">
                            <p>1 - Consultancy for Community Engagement for Homage and Courtesy Call to The King and 
                              The Council of Chiefs of Otumara in OML 40 – 2019</p>
                            <p>2 - Provision For Consultancy service for Oghareki Field Manifold Leak Incident: Request for 
                              Engagement of Host Community – OML 49</p>
                            <p>3 - Provision Of Homage Required for The Courtesy Visit to The King and His Council of Chiefs 
                              of Opuama in OML 40 - 2019</p>
                            <p>4 - Provision For Oghareki Field Manifold Leak Incident: Request for Engagement of Host 
                              Community – OML 49 - 2019</p>
                            <p>5 - Community Engagement for The Provision for Gift Items for Courtesy Call to The King of 
                              Udu and His Council of Chiefs – OML 65– 2019</p>
                            <p>6 - The Provision for Homage for Courtesy Call to The Chairman Akwa Ibom State Council of 
                              Chiefs – OML 13 – 2019</p>
                            <p>7 - Provision Of Logistics to Secure Drilling FTO from Access Communities – OML 65 – 2018</p>
                            <p>8 - Logistics Services for the Visitation/Homage To Enogie & Chiefs of Ologbo – OML 111 - 2019</p>
                            <p>9 - The Supply of End of Year Gift Items in OML 11 – 2017</p>
                            <p>10 - The Provision of End of Year Gift Items in OML 20 – 2018 </p>
                            <p>11 - The Provision of Utilities at Enogie's Palace in Ologbo OML 111 – 2018 </p>
                            <p>12 - The Provision and Management of workers remunerations, sundry payments and others 
                              operations logistics for the Drilling operations in OML 111. 2019 – 2021 </p>
 </p>
                  <div className="mt-4 pt-1">
                    
                  </div>
                </div>
              </Col>
              <Col lg={5} className="offset-lg-1">
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div className={ (counteritem.id !== '1') ? 'mt-4 pt-3' : 'mt-4' } key={key}>
                      <div className="media">
                        <div className="count-box bg-soft-primary text-center">
                          <h3 className="counter-count mb-1 text-primary"> 
                          
                            <span className="count-plus text-primary"> </span></h3>
                          <p className="text-uppercase text-muted mb-0 f-14">{counteritem.title} </p>
                        </div>
                        <div className="media-body pl-4">
                          <p className="text-muted mb-0 mt-3">{counteritem.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
