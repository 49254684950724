import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

//import images
import Img1 from "../assets/images/features/img-1.png";

export default class Pricing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pricing: [
        {
          id: 1,
          title: "Starter",
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          icon: "mdi-account",
          titlePrimary: false,
          regularprice: "$9.99",
          saleprice: "$8.99",
          pricingTag: false,
          child: [
            {
              btitle: "Unlimited",
              title: "Target Audience",
              icon: "mdi-checkbox-marked-circle text-success",
            },
            {
              btitle: "1",
              title: "User Account",
              icon: "mdi-checkbox-marked-circle text-success",
            },
            {
              btitle: "100+",
              title: "Video Tuts",
              icon: "mdi-close-circle text-danger",
            },
            {
              btitle: "Public",
              title: "Displays",
              icon: "mdi-close-circle text-danger",
            },
          ],
        },
        {
          id: 2,
          title: "Personal",
          titlePrimary: true,
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          icon: "mdi-account-multiple text-primary",
          regularprice: "$19.99",
          saleprice: "$18.99",
          pricingTag: true,
          child: [
            {
              btitle: "Unlimited",
              title: "Target Audience",
              icon: "mdi-checkbox-marked-circle text-success",
            },
            {
              btitle: "1",
              title: "User Account",
              icon: "mdi-checkbox-marked-circle text-success",
            },
            {
              btitle: "100+",
              title: "Video Tuts",
              icon: "mdi-close-circle text-success",
            },
            {
              btitle: "Public",
              title: "Displays",
              icon: "mdi-close-circle text-danger",
            },
          ],
        },
        {
          id: 3,
          title: "Ultimate",
          description:
            "Semper urna veal tempus pharetra elit habisse platea dictumst.",
          titlePrimary: false,
          icon: "mdi-account-multiple-plus",
          regularprice: "$29.99",
          saleprice: "$28.99",
          pricingTag: false,
          child: [
            {
              btitle: "Unlimited",
              title: "Target Audience",
              icon: "mdi-checkbox-marked-circle text-success",
            },
            {
              btitle: "1",
              title: "User Account",
              icon: "mdi-checkbox-marked-circle text-success",
            },
            {
              btitle: "100+",
              title: "Video Tuts",
              icon: "mdi-close-circle text-success",
            },
            {
              btitle: "Public",
              title: "Displays",
              icon: "mdi-close-circle text-success",
            },
          ],
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-light bg-featuress">
          <Container>
            <Row className="align-items-center">
              <Col lg={5}>
                <div className="mt-4 home-img">
                  <div className="animation-2"></div>
                  <div className="animation-3"></div>
                  <img src={Img1} className="img-fluid" alt="" />
                </div>
              </Col>
              <Col lg={6} className="offset-lg-1">
                <div className="mt-5">
                  <p className="text-uppercase">Who We Are</p>
                  <h2>OUR KEY PERSONNEL</h2>
                  <p className="mt-4 text-muted">
                    <b>MR UBOGU EMAMUZO GABRIEL – Director</b> <p></p>
                    Mr. UBOGU Emamuzo Gabriel is an Accountant/Finance expert by
                    profession. Who has been working in the Oil and Gas sector
                    for over 13 years. His dedication to work and business has
                    earned him feat in managerial position, which gave him the
                    opportunity to develop his remarkable skills in the field of
                    Finance and consultancy Management.
                    <p></p>
                    Mr. Ubogu Emamuzo Gabriel is currently the Chief Executive
                    Officer of GYN Global Resources and also one of the
                    directors of Govon Creationz Services and an astute business
                    man with vast interests across various industry segments,
                    including banking and finance. A graduate of Benson Idahosa
                    University where he obtained is BSc in Finance & Accounts
                    and his MSc in Financial Management from the University of
                    Benin, Benin City. Happily Married and blessed with two (2)
                    lovely boys
                    <p></p>
                    <b>MRS UBOGU YVONNE IVIE – Director</b> <p></p>
                    UBOGU Yvonne Ivie (Mrs.) is a journalist by profession and
                    an astute business woman with vast interests across various
                    industry segments, including hospitality, logistics, event
                    management, aviation. He gained her BSc from University of
                    Benin, Benin City, Nigeria. And currently studying for her
                    masters in the UK (Master of Multimedia Journalism in
                    Sheffield Hallam University – Sheffield, UK) . She is also
                    the CEO of Govon Creationz Services, a company vast in
                    Logistics, Event Management, Consultancy and General
                    Management.
                    <p></p>
                    <b>
                      CHARLES AIYUDUBIE, Esq.LLB, BL – Secretary/Legal Adviser
                    </b>
                    <p></p>
                    <b>IFEANYI OFIKULU – IT Administrator</b> <p></p>
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
