import React, { Component } from "react";
import { Col, Container, Row } from "reactstrap";

import { Link } from "react-router-dom";

import Img1 from '../assets/images/img.jpg';
import HomeUrl from '../assets/images/home-border.png';

export default class Services extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          id: 'service1',
          
          title: "OUR CAPACITY AND CAPABILITY",
          description: <p>Following the successful completion of various projects between Nigerian Petroleum Development Company(NPDC) and other JV partners, GYN 
          has witnessed a success story in the area of JV operations.  
                                     <br /> Our success stories have stood us out as testimonies to our high professionalism, team work and 
deployment.
</p>
          },
        {
          id: 'service2',
          title: "COMPANY STRATEGY",
          description: <p><b>• Purpose:</b> To be a leader in the general Merchandise and real estate industry by providing 
          enhanced services, relationship and profitability. 
                                     <br /> <b>• Vision:</b> To make GYN Global Resources a pride of customers based on trust, integrity and 
quality services.

                            <br /><b>• Mission statement:</b> 
</p>
        },
        {
          id: 'service3',
          title: "EXECUTED JOBS BY GYN GLOBAL RESOURCES",
          description: <p>1. Consultancy for Community Engagement for Homage and Courtesy Call to The King and
          The Council of Chiefs of Otumara in OML 40 – 2019.
                                     <br /> 2. Provision For Consultancy service for Oghareki Field Manifold Leak Incident: Request for
Engagement of Host

                           
                            
</p>
        },
      ],
      counters: [
        {
          id: '1',
          extraclass: '',
          
          title: "1",
          description: <p>Fidelity Bank Plc.
                            <br /> 56, Mission Road
                            <br />Benin City
                            <br />Edo State
                            
</p>
        },
        {
          id: '2',
          extraclass: 'pt-3',
         
          title: "2",
          description:  <p>Access Bank Plc.
                        <br /> 164 Sapele Road
                        <br />Benin City
                        <br />Edo State
          
</p>
        },
        
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className="section bg-services" id="services">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                  <h3 className="title-heading mt-4">ABOUT US </h3>
                  <p className="text-muted f-17 mt-3">GYN Global Resources was incorporated in 2016 to carry out general contract and merchandise in 
all sector of trade and logistics services. This was owing to the gap that was in existence in the
logistics services, it was then proactively strategized to cover this gap by providing effective and 
modern solutions to logistics services in the Oil and Gas sector of the economy. 
                           <br /> In between 2017 to date, GYN has participated in several competitive bid alongside other companies 
for the Nigerian National Petroleum Corporation(NNPC)/Nigerian Petroleum Development Company(NPDC) JV operational activities.</p>

                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            <Row className="mt-5 pt-4">
              {/* Render Footer Link */}
              {this.state.services.map((item, key) => (
                <Col lg={4} key={key}>
                  <div className="services-box p-4 mt-4">
                    <div className=" bg-soft-primary">
                    </div>

                    <h5 className="mt-4">{item.title}</h5>
                    <p className="text-muted mt-3">{item.description}</p>

                    <div className="mt-3">
                      <Link to="strength" className="text-primary f-16">Learn More <i className="mdi mdi-arrow-right ml-1"></i></Link>
                    </div>

                  </div>
                </Col>
              ))}
            </Row>
            <Row className="align-items-center mt-5 pt-4" id="counter">
              <Col lg={6}>
                <div className="pr-4 mt-4">
                  <p className="text-uppercase"></p>
                  <img src={Img1} className="img-fluid" alt="" />
                  <h3>FINANCIAL CAPABILITY</h3>
                  <p className="text-muted mt-3">Over the years, GYN has mastered the art of General Logistics and services by offering quality, 
multivariate and bespoke services to our valued clients leading to the exponential growth witnessed 
by the company. <p></p>
The effectiveness of our business strategy has seen appreciable growth patterns in the core financial 
indicators of GYN that corporate entities are measured in the business environment. Our revenue 
has experienced over 100% growth over a 3-year period, with 2019 financial year accounting for 
about 60% of the aggregated earnings for the period. <p></p>
GYN will be leveraging on this robust financial capacity to ensure financial constraints are not 
critical factors to be considered by our existing and prospective clients in the execution of
deliverables and achievement of operational milestones on behalf of our clients. <p></p>
We proposed to continue with the strong partnerships/relationship with our financial service 
providers, both local and international, to enable us have access to massive lines of credit to finance 
our operations irrespective of the capacity, tenure and geographically dispersed our clients’ 
operations might be.
             <p></p>
             We proposed to continue with the strong partnerships/relationship with our financial service 
providers, both local and international, to enable us have access to massive lines of credit to finance 
our operations irrespective of the capacity, tenure and geographically dispersed our clients’ 
operations might be.               
                            <p></p>
                            Here are the names and addresses of our reputable bankers who at any instance can attest to our 
credibility:
 </p>
                  <div className="mt-4 pt-1">
                    
                  </div>
                </div>
              </Col>
              <Col lg={5} className="offset-lg-1">
                <div className="counter-box">
                  {this.state.counters.map((counteritem, key) => (
                    <div className={ (counteritem.id !== '1') ? 'mt-4 pt-3' : 'mt-4' } key={key}>
                      <div className="media">
                        <div className="count-box bg-soft-primary text-center">
                          <h3 className="counter-count mb-1 text-primary"> 
                          
                            <span className="count-plus text-primary"> </span></h3>
                          <p className="text-uppercase text-muted mb-0 f-14">{counteritem.title} </p>
                        </div>
                        <div className="media-body pl-4">
                          <p className="text-muted mb-0 mt-3">{counteritem.description}</p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
