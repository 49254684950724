import React, { Component } from "react";
import { Link } from "react-router-dom";

import { Col, Container, Row } from "reactstrap";
import Slider from "react-slick";


import HomeUrl from '../assets/images/home-border.png';

import Img1 from '../assets/images/users/img-1.png';
import Img2 from '../assets/images/users/img-2.png';
import Img3 from '../assets/images/users/img-3.png';
import Img4 from '../assets/images/users/img-4.png';

export default class Contact extends Component {
  constructor(props) {
    super(props);
    this.state = {
      teams: [
        {
          id: 1,
          img: Img1,
          name: 'Calvin Hubbard',
          nickname: '#Calvin',
          description: 'Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.'
        },
        {
          id: 2,
          img: Img2,
          name: 'Jeremiah Eskew',
          nickname: '#Jeremiah',
          description: 'Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.'
        },
        {
          id: 3,
          img: Img3,
          name: 'Zachary Tevis',
          nickname: '#Zachary',
          description: 'Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.'
        },
        {
          id: 4,
          img: Img4,
          name: 'William Alderman',
          nickname: '#William',
          description: 'Anterdum maleada fames Integer molestie ante primis nulla facibus felis ulputate platea elit.'
        },
      ],
    };
  }

  render() {
    var temsettings = {
      autoplay: true,
     
      speed: 300,
      infinite: false,
      arrows: false,
      slidesToShow: 2,
      slidesToScroll: 1,
    };

    const teamslides = this.state.teams.map(( teamindex) => {
      return (
        <div className="team-box p-3" key={teamindex}>
          
        </div>
      );
    });
    return (
      <React.Fragment>
        <section className="section pt-0" id="team">
          <Container>
            <Row>
              <Col lg="12">
                <div className="title-box text-center">
                <p className="text-uppercase">Who We Are</p>
                  <h3 className="title-heading mt-4">OUR KEY PERSONNEL </h3>
                  <p className="text-muted f-17 mt-3"><b>MR UBOGU EMAMUZO GABRIEL – Director</b> <p></p>
Mr. UBOGU Emamuzo Gabriel is an Accountant/Finance expert by profession. Who has been 
working in the Oil and Gas sector for about 11 years. His dedication to work and business has 
earned him feat in managerial position, which gave him the opportunity to develop his 
remarkable skills in the field of human resources and consultancy. </p>
                  <Link to="#" className="btn btn-outline-primary">Discover More</Link> <p></p>
                  <img src={HomeUrl} height="15" className="mt-3" alt="" />
                </div>
              </Col>
            </Row>
            
              <Col lg="12">
                <div className="">
                  <Slider {...temsettings}>
                    {teamslides}
                  </Slider>
                </div>
              </Col>
            
          </Container>
        </section>
        
      </React.Fragment>
    );
  }
}
